:root {
  --primary-cl:#4BA647;
  --secondary-cl:#A0A0A0;
  --tertiary-cl:#E53228;
  --black-cl:#1D1C1A;
  --grey-cl:#F2F2F2;


  --section-width-lg:85%;
}
@media(max-width:768px){
    :root{
      --section-width-lg:90%;
    }
}
@media(max-width:580px){
  :root{
    --section-width-lg:90%;
  }
}

html{
  overflow-x: hidden;
}

@font-face {
  font-family: 'popins_medium'; 
  src: url('./assets/fonts/Poppins-Medium.woff') 
}
@font-face {
  font-family: 'popins_bold'; 
  src: url('./assets/fonts/Poppins-Bold.woff') 
}
@font-face {
  font-family: 'popins_light'; 
  src: url('./assets/fonts/Poppins-Light.woff') 
}
@font-face {
  font-family: 'popins_regular'; 
  src: url('./assets/fonts/Poppins-Regular.woff') 
}
@font-face {
  font-family: 'popins_semibold'; 
  src: url('./assets/fonts/Poppins-SemiBold.woff') 
}
@font-face {
  font-family: 'popins_thin'; 
  src: url('./assets/fonts/Poppins-Thin.woff')
}
@font-face {
  font-family: 'popins_extralight'; 
  src: url('./assets/fonts/Poppins-ExtraLight.woff')
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu-bar-enabled{
  overflow: hidden !important;
}

.loader-active{
  overflow: hidden !important;
}
