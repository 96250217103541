@font-face {
  font-family: "poppins-regular";
  src: url(../fonts/Poppins-Regular.woff) format(woff);
}
@font-face {
  font-family: "poppins-medium";
  src: url(../fonts/Poppins-Medium.woff) format(woff);
}
@font-face {
  font-family: "poppins-bold";
  src: url(../fonts/Poppins-Bold.woff) format(woff);
}
@font-face {
  font-family: "poppins-light";
  src: url(../fonts/Poppins-Light.woff) format(woff);
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap");
.l-map {
  /* height: 550px; */
  /* width: 700px !important; */
  /* width: 100%; */
}
.google-maps {
  position: relative;
  /* padding-bottom: 75%; */
  display: none !important;
  height: 0;
  width: 100% !important;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 600px !important;
  height: 100% !important;
}
body {
  font-family: "poppins-regular";
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
a {
  text-decoration: none;
}
.wrapper {
  width: 80%;
  margin: 0 auto;
}
.iframe {
  width: 100%;
  height: 100vh;
}
.l-map {
  width: 100%;
  height: 800px;
}
/* tooltip */
.tooltip {
  z-index: 10000000000;
}
.react-tooltip {
  z-index: 99999;
  background: yellow;
}
.toolimage {
  width: 400px;
  z-index: 1000;
}
.tooltitle {
  text-align: center;
  font-size: 18px;
  margin: 10px;
}
#project {
  width: 100%;
  border-radius: 10px;
  margin-top: 22px;
}

.hero-banner {
  position: relative;
  cursor: pointer;
  width: 100%;
  padding-bottom: 50%;
  margin-top: 0px;
  top:-124px;
}
.desktop-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ipad-image {
  position: absolute;
  top: 0;
  display: none;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  display: none;
}
.mobile-image {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: auto;
  object-fit: cover;
  display: none;
}
/* slider */

.into {
  margin-top: -50x;
}
#logo-main {
  width: 135px;
  height: auto;
}

#features .walkthrough {
  z-index: 9;
}
#features p {
  color: #1c2951;
}
#features .container {
  font-weight: 600;
  margin-bottom: 65px !important;
  border-radius: 15px;
}
#features .container p {
  font-size: 1rem;
}

#features .container i {
  font-size: 2.5rem;
}
.key_features {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hero-banner {
  position: relative;
  width: 100%;
  height: 813px;
  padding-bottom: 50%;
  margin-top: 0px;
}

.desktop-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ipad-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  display: none;
}

.mobile-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  display: none;
}
.fade-in {
  animation-name: fade-in;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
.mob-hotspot {
  display: none;
}
.section-with-shape-divider {
  background-color: #ee3b3533;
}
.loc-map {
  width: 100%;
  height: auto;
}
.txt-white {
  color: #fff;
}
.form-div input {
  width: 250px;
}
.form-div input::placeholder {
  color: rgb(163, 157, 157);
  font-size: 0.7rem;
}
.form-div {
  display: flex;
  justify-content: center;
  border: 1px solid rgb(194, 190, 190);
  border-radius: 20px;
}
.video-background {
  width: 90vw;
  height: 33vw;
  position: relative;
}
.about_desc {
  font-weight: 500;
}
.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 765px;
  object-fit: cover;
}
.amenite__div {
  position: relative;
}
.amenite__name {
  position: absolute;
  color: #fff;
  z-index: 2;
  bottom: 7px;
  left: 15px;
  font-size: 1rem;
}
.info-div {
  display: none;
  height: 225px;
  position: absolute;
  border: 1px solid rgb(188, 184, 184);
  border-radius: 10px;
  width: 93%;
  z-index: 2;
  background-color: #ffff;
}
.imgs img {
  width: 63px;
  height: auto;
}
/* -------------------------hamburger--------------------- */

.hamburger-lines {
  display: block;
  height: 26px;
  width: 32px;
  z-index: 2;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
}

.hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}
.checkbox {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

input[type="checkbox"]:checked ~ .logo {
  display: none;
}
.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
}
html.modal-enabled {
  overflow: hidden !important;
}
/* --------------------------------hamburger ends------------------ */
.form-div button {
  width: 20px;
}
.footer-separator {
  width: 90%;
}
#footer {
  font-size: 1.1rem;
}
.reach_out {
  font-size: 18px;
  font-weight: 500;
}
.location h6,
.phone h6,
.Email h6 {
  font-size: 16px !important;
}
.award_section {
  font-size: 18px;
}
.small-heading {
  font-size: 14px;
}
.socialmedia i {
  font-size: 20px;
}
/* .socialmedia span{
  margin: 0px 5px 0px 5px ;
} */
.chat-options-1 {
  display: none;
  position: fixed;
  bottom: 50px;
  right: 13px;
  padding: 10px;
  border-radius: 15px;
  z-index: 2000;
}
.chat-options-1 img {
  width: 25px;
}
.chat-options-1 img:hover {
  width: 30px;
}
.chat-options-2 {
  display: none;
  background-color: #ee3c35;
  position: fixed;
  bottom: 20px;
  padding: 10px;
  left: 25px;
  border-radius: 25px;
  z-index: 2000;
  height: 50px;
  color: green !important;
}
.chat-options-2 a {
  margin: 0;
  text-decoration: none !important;
  padding: 0;
}
.chat-options-2 i {
  font-size: 30px;
  margin: 0;
}
.chat-options-2:hover {
  background-color: #fff;
  border: 1px solid #ee3c35;
}
.chat-options-2:hover > .phn-icon > a {
  color: #ee3c35;
}
.phn-icon a {
  color: #fff;
}
body {
  position: relative;
}
.project-logo {
  height: auto;
  width: 300px;
}
.details-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 150px;
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid rgb(239 91 98);
}
.details-div i {
  font-size: 1.5rem;
  color: rgb(239 91 98) !important;
}
.details-div p {
  color: rgb(239 91 98) !important;
}
.details-div:hover {
  background-color: rgb(239 91 98);
}
.details-div:hover > i {
  color: #fff !important;
}
.details-div:hover > p {
  color: #fff !important;
}
#footer {
  background: #f8fbfe !important;
  border: none !important;
}
#footer h6 {
  line-height: 20px !important;
}
#footer h6,
#footer span i,
#footer .text-light {
  color: rgb(28 41 81) !important;
}
#footer .copyright {
  background-color: rgb(28 41 81) !important;
}
#footer .copyright h6 {
  font-size: 15px;
  color: #fff !important;
}
.footer-logo {
  height: auto;
  width: 150px;
  margin-top: 10px;
}
.desk-hotspot {
  display: block;
  /* max-width: 1200px;
  margin: 0 auto; */

  position: relative;
}
.tablet-hotspot {
  display: none;
}
.mob-hotspot {
  display: none;
}
.video-iframe {
  width: 100%;
  height: 813px;
}
.card {
  border: 1px solid rgb(183, 183, 186);
  position: absolute;
  z-index: 9;
}
.img_amenitie .close {
  position: absolute;
  color: #fff;
  background-color: #fffefe;
  padding: 15px;
  border: 1px solid blue;
  right: 154px;
  top: 0;
  opacity: 1 !important;
}

.img_amenitie {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 91vw;
  height: 563px;
  z-index: 9;
  background-color: transparent !important;
}
.amenitie_lg_img {
  border-radius: 15px;
}
.footer_logo_heading {
  font-size: 13px;
  margin: 0;
  padding: 0;
  color: rgb(28 41 81);
}
.ri-add-line {
  color: #fff;
  font-size: 16px;
}
.arrow,
.arrow1,
.arrow2 {
  position: absolute;
  transform: translate(-50%, -50%);
  transform: rotate(208deg);
  cursor: pointer;
  z-index: 9;
  /* background: transparent url(../img/arrows.png) no-repeat 0 0; */
  width: 70px;
  height: 100px;
  display: flex !important;
  align-items:center;
  justify-content: center;
  flex-direction: column;

}

.arrow span,
.arrow1 span,
.arrow2 span {
  display: block;
  width: 1.5vw;
  height: 1.5vw;
  border-bottom: 4px solid rgb(252, 252, 253);
  border-right: 4px solid rgb(255, 255, 255);
  transform: rotate(45deg);
  margin: -3px;
  animation: animate 2s infinite;
}

.desk-hotspot .image-hotspots{
   max-width:1070px !important;
}

.arrow span:nth-child(2),
.arrow1 span:nth-child(2),
.arrow2 span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3),
.arrow1 span:nth-child(3),
.arrow2 span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
@media (max-width: 1200px) {
  .desk-hotspot {
    display: none;
  }
  .tablet-hotspot {
    display: block;
    position: relative;
  }
  .amenitie_lg_img {
    width: 91%;
  }
  .img_amenitie {
    height: 465px;
  }
  .img_amenitie .close {
    right: 53px;
  }
  .arrow1 {
    top: 63%;
    left: 32%;
  }
}
@media only screen and (max-width: 768px) {
  .google-maps {
    display: block;
  }
  .l-map {
    display: none;
  }
  .footer_logo_link {
    width: 103px;
  }
  .footer_logo_heading {
    font-size: 9px;
  }
  .footer-logo {
    width: 125px;
  }
  .location h6,
  .phone h6,
  .Email h6 {
    font-size: 14px !important;
  }
  .video-background video {
    height: 465px;
  }
  .video-background {
    height: 50vw;
  }
  .img_amenitie .close {
    right: 11px;
    top: 41px;
  }
  .amenitie_lg_img {
    width: 100%;
  }
  .amenite__name {
    font-size: 0.8rem;
  }
  .desktop-image {
    display: none;
  }
  .ipad-image {
    display: block;
  }
  .hero-banner {
    margin-top: 0px;
    height: 420px;
  }
  .video-iframe {
    height: 420px;
  }
  .form-div button {
    width: 100% !important;
  }
  .white-bg {
    margin-top: 50px;
    padding-top: 50px;
  }
  .service-box-style-02 .feature-box {
    padding: 40px 20px;
    height: 200px;
  }
  .service-box-style-02 .white-bg {
    margin-top: 10px;
  }
  #logo-main {
    width: 90px;
    height: auto;
  }
  .mob-hotspot {
    display: none;
  }
  .phone h6 {
    font-size: 0.9rem;
  }
  .footer-separator {
    width: 95%;
  }
  .details-div {
    width: 95%;
  }
}
@media only screen and (max-width: 576px) {
  .footer_logo_heading {
    font-size: 14px;
  }
  .footer_logo_link {
    width: auto;
    margin-bottom: 20px;
  }
  .arrow2 {
    top: 73%;
    left: 33%;
  }
  .arrow2 span {
    border-bottom: 2px solid rgb(252, 252, 253);
    border-right: 2px solid rgb(255, 255, 255);
  }
  .reach_out {
    font-size: 18px;
  }
  .location h6 {
    letter-spacing: 1px;
    font-size: 15px;
  }
  .img_amenitie {
    height: 215px;
  }
  .img_amenitie .close {
    right: 1px;
    top: -2px;
  }
  .form-div input,
  .form-div button {
    height: 32px;
  }
  .ipad-image {
    display: none;
  }
  .mobile-image {
    display: block;
  }
  .form-div {
    border: none !important;
  }
  .hero-banner {
    margin-top: 0px;
    height: 400px;
    padding-bottom: 0%;
  }
  .video-iframe {
    height: 400px;
  }
  .white-bg {
    margin-top: 100px;
    padding-top: 50px;
  }
  .mobile-image {
    height: 400px;
  }
  .l-map {
    height: 550px;
  }
  #foot-social {
    display: none;
  }
  #logo-main {
    width: 85px;
    height: auto;
  }
  .desk-hotspot {
    display: none;
  }
  .tablet-hotspot {
    display: none;
  }
  .mob-hotspot {
    display: block;
    position: relative;
  }
  .image-hotspot .circle {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 15px;
    height: 15px;
    margin: -0.666em auto auto -0.666em;
    background: #ccc;
    border-radius: 50%;
    opacity: 0.9;
    transform-origin: 50% 50%;
    transition: opacity 0.2s ease-in, transform 0.1s ease-out;
    color: #fff;
    font-size: 1em;
    padding: 0;
    text-align: center;
    line-height: 28px;
    overflow: hidden;
  }
  .image-hotspot strong {
    font-size: 13px;
  }
  .image-hotspot .circle {
    width: 10px;
    height: 10px;
  }
  .image-hotspot strong {
    font-size: 8px;
    transform: translate3d(-117%, -62%, 0);
  }
  .video-background {
    width: 90vw;
    height: 250px;
  }
  .video-background video {
    height: auto;
    margin-bottom: 10px;
  }
  .info-div {
    height: 190px;
    width: 94%;
    overflow: scroll;
    left: 6px;
  }
  .amenite__name {
    left: 5px;
    bottom: 3px;
    padding: 1px 5px 1px 5px;
  }
  .imgs img {
    width: 45px;
    height: 50px;
  }
  .phone h6 {
    font-size: 0.7rem;
  }
  .amenite__div {
    width: 65vw;
    margin: 0px 8px 0px 8px;
  }
  .mobile-amenites {
    height: 58vh;
  }
  .footer-separator {
    width: 100%;
  }
  .sticky-body {
    margin-top: -21px !important;
  }
  .chat-options-2 {
    bottom: 59px;
    left: 8px;
  }
  .footer-logo {
    height: auto;
    width: 100px;
    margin-top: 10px;
  }
  .project-logo {
    width: 180px;
  }
  #features .container i {
    font-size: 1.5rem;
  }
  #features .container p {
    font-size: 0.8rem;
  }

  .arrow2{
    width:15px;
  }

}
@media all and (min-width: 1400px) {
  .arrow{
    width:25px;
  }
}
@media all and (max-width: 1500px) {
  .l-map {
    /* width: 550px; */
  }
}
@media all and (max-width: 980px) {
  .image-hotspot .circle i {
    font-size: 16px;
  }
  .image-hotspot .circle {
    width: 20px !important;
    height: 20px !important;
    line-height: 18px !important;
  }
  .arrow1 {
    top: 60%;
    left: 30%;
  }
}
@media all and (max-width: 768px) {
  .image-hotspot .circle i {
    font-size: 12px;
  }
  .image-hotspot .circle {
    width: 13px !important;
    height: 13px !important;
    line-height: 12px !important;
  }
  .wrapper {
    width: 90%;
    margin: 0 auto;
  }
  html .image-hotspot-primary .ring {
    display: none;
  }
  .arrow1{
    width: 35px;
  }
}
@media all and (max-width: 640px) {
  .l-map {
    /* width: 450px; */
    margin: 0 auto;
    height: 400px;
  }
  .arrow1 {
    top: 48%;
    left: 30%;
  }
}
@media all and (max-width: 480px) {
  .l-map {
    /* width: 350px !important; */
    margin: 0 auto;
    margin-left: 20px;
    height: 400px;
  }
  .toolimage {
    width: 250px;
  }
  html .image-hotspot-primary .ring {
    display: none;
  }
  .arrow2 {
    top: 26%;
    left: 32%;

  }
}

@media all and (max-width: 400px) {
  .l-map {
    width: 100% !important;
    /* margin: 0 auto;
    margin-left: 20px; */
    height: 400px;
  }
}

@media all and (max-width: 425px) {
  .arrow2 {
    top: 10%;
    left: 33%;
  }
}
@media all and (max-width: 375px) {
  .arrow2{
    top: 4%;
    left: 28%;
  }
}

@media all and (max-width: 330px) {
  .arrow2{
    height: 60px;
  }
}